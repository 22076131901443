import React from 'react';
import styled from 'styled-components';
// import styled, { keyframes } from 'styled-components';
import borgwarner from '../assets/images/borgwarner.jpg';
import eit from '../assets/images/eit.jpg';
import lean from '../assets/images/lean.jpg';
import uptec from '../assets/images/uptec.png';
import hutchinson from '../assets/images/hutchinson.png';
import gestamp from '../assets/images/gestamp.png';
import bosch from '../assets/images/bosch.png';
import lingote from '../assets/images/lingote.jpg';
import amtrol from '../assets/images/amtrol.jpg';
import afixcode from '../assets/images/afixcode.png';
import ford from '../assets/images/ford.png';
import purem from '../assets/images/purem.jpg';
import ficosa from '../assets/images/ficosa.jpg';
import cmp from '../assets/images/logo_cmp.png';
import a2ai from '../assets/images/a2ai.png';
import inycom from '../assets/images/inycom.png';
import wavecom from '../assets/images/wavecom.png';

import layoutStyle from '../styles/layout';
import { contentHeaderStyles } from '../styles/text';
import { media } from '../styles/constants';
import { padding } from '../styles/layout';

// const rotate = keyframes`
//     from {
//         transform: translateX(0);
//     }
//     to {
//         transform: translateX(-100%);
//     }
// `;

const PartnerSection = styled.div`
  ${layoutStyle};
  ${contentHeaderStyles};

  h2 {
    text-align: center;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  padding: 64px 0;
  background: white;

  @media (min-width: ${media.bigDesktop}px) {
    max-width: 100%;
    padding: ${padding.desktop};
  }
`;

// const LogosSlider = styled.div`
//   overflow-x: hidden;
//   position: relative;

//   &:before {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 15%;
//     height: 100%;
//     background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
//     content: '';
//     z-index: 2;
//   }

//   &:after {
//     position: absolute;
//     top: 0;
//     right: 0;
//     width: 15%;
//     height: 100%;
//     background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
//     content: '';
//     z-index: 2;
//   }
// `;

// const Overflow = styled.div`
//   display: flex;
//   padding: 0.5rem 1rem;
//   overflow: hidden;
// `;

// const LogosWrapper = styled.div`
//   display: flex;
//   justify-content: space-around;
//   animation: ${rotate} 30s linear infinite;
// `;

const LogosWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
  padding: 0 10px 70px;

  @media (min-width: ${media.tabletLandscape}px) {
    width: 100%;
  }
  @media (min-width: ${media.desktop}px) {
    padding: 0 70px 70px;
    width: 100%;
  }
  @media (min-width: ${media.bigDesktop}px) {
    padding: 0 70px 70px;
    width: 80%;
  }
`;

const LogoItem = styled.div`
  margin-left: 1rem;
  padding: 0 1rem 1rem;
  aspect-ratio: 30/9;
  display: grid;
  place-items: center;
  border-radius: 1rem;

  img {
    width: 10rem;
    margin-inline: 1rem;
    filter: grayscale(100%);

    @media (max-width: ${media.tabletPortrait}px) {
      width: 5rem;
    }
  }

  #cmp {
    width: 7rem;

    @media (max-width: ${media.tabletPortrait}px) {
      width: 4rem;
    }
  }

  #lean,
  #inycom,
  #a2ai {
    width: 7rem;

    @media (max-width: ${media.tabletPortrait}px) {
      width: 3rem;
    }
  }

  #ford {
    width: 9rem;

    @media (max-width: ${media.tabletPortrait}px) {
      width: 4rem;
    }
  }
`;

function Logos() {
  return (
    <PartnerSection>
      <h2>Partners</h2>

      <LogosWrapper>
        <LogoItem>
          <img src={uptec} />
        </LogoItem>
        <LogoItem>
          <img src={lean} id="lean" />
        </LogoItem>
        <LogoItem>
          <img src={eit} />
        </LogoItem>
        <LogoItem>
          <img src={a2ai} id="a2ai" />
        </LogoItem>
        <LogoItem>
          <img src={inycom} id="inycom" />
        </LogoItem>
        <LogoItem>
          <img src={afixcode} />
        </LogoItem>
        <LogoItem>
          <img src={wavecom} />
        </LogoItem>
      </LogosWrapper>

      <h2>Customers</h2>
      {/* <LogosSlider>
        <Overflow>
          <LogosWrapper>
            <LogoItem>
              <img src={ford} id="ford" />
            </LogoItem>
            <LogoItem>
              <img src={borgwarner} />
            </LogoItem>
            <LogoItem>
              <img src={hutchinson} />
            </LogoItem>
            <LogoItem>
              <img src={gestamp} />
            </LogoItem>
            <LogoItem>
              <img src={bosch} />
            </LogoItem>
            <LogoItem>
              <img src={cmp} id="cmp" />
            </LogoItem>
            <LogoItem>
              <img src={lingote} />
            </LogoItem>
            <LogoItem>
              <img src={amtrol} />
            </LogoItem>
            <LogoItem>
              <img src={purem} />
            </LogoItem>
            <LogoItem>
              <img src={ficosa} />
            </LogoItem>
          </LogosWrapper> */}
      <LogosWrapper>
        <LogoItem>
          <img src={ford} id="ford" />
        </LogoItem>
        <LogoItem>
          <img src={borgwarner} />
        </LogoItem>
        <LogoItem>
          <img src={hutchinson} />
        </LogoItem>
        <LogoItem>
          <img src={gestamp} />
        </LogoItem>
        <LogoItem>
          <img src={cmp} id="cmp" />
        </LogoItem>
        <LogoItem>
          <img src={bosch} />
        </LogoItem>
        <LogoItem>
          <img src={lingote} />
        </LogoItem>
        <LogoItem>
          <img src={amtrol} />
        </LogoItem>
        <LogoItem>
          <img src={purem} />
        </LogoItem>
        <LogoItem>
          <img src={ficosa} />
        </LogoItem>
      </LogosWrapper>
      {/* </Overflow>
      </LogosSlider> */}
    </PartnerSection>
  );
}

export default Logos;
